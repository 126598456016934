import React, { useState, useEffect } from 'react'
import { AuthenticationLayout as Layout } from 'presentation/components/AuthenticationLayout'
import { navigate } from 'gatsby'
import { getFingerprintAndHash } from 'scripts/getFingerprintAndHash'
import { useSnackbar } from 'presentation/components/Snackbar/useSnackbar'
import { useIpAddress } from 'api/useIpAddress'
import { cookies } from 'scripts/cookies'
import axios from 'axios'
import { fullUrl } from 'scripts/fullUrl'
import { SamlAuthPostResponse } from 'types/ApiResponse'
import { Loading } from 'presentation/components/Loading'
import { identitiesCount } from 'api/identitiesCount'
import { ToAccountChooserState, ToTransferState } from 'types/NavigateState'
import { UADataKeys } from 'scripts/UADataKeys'
import { Navigator } from 'types/window'
import { FingerPrint, FingerPrintHash } from 'types/FingerPrint'
import { useTranslation } from 'react-i18next'
import { SendLockedSessionTokenToExtension } from 'scripts/sendMessageToExtension'
import { getDeviceUuid } from 'scripts/getDeviceUuid'
import { getExternalIdps } from 'api/getExternalIdps'
import OpenEyeSvg from 'images/openEye.svg'
import CloseEyeSvg from 'images/closeEye.svg'
import { getSubdomain } from 'scripts/getSubdomainForHost'
import { ExternalIdp } from 'types/ExternalIdp'
import { getTeam } from 'api/getTeam'
import { LOCKED_RELAY_STATE_SEPARATOR } from 'scripts/lockedRelayStateSeparator'
import { sentryCaptureMessage } from 'scripts/sentryCaptureMessage'
import { getApps } from 'api/getApps'
import { convertAppsToExtensionApps } from 'scripts/convertAppsToExtensionApps'
import { SendUrlMatchFormBasedAuthParams } from 'scripts/sendMessageToExtension/sendUrlMatchFormBasedAuthParams'

interface Props {
  samlRequest: string
  relayState: string
  teamId: number
  appKey?: string
  appName?: string
  appId?: number
  hasValidForcedPasswordLoginDisplayToken: boolean
}

const Index: React.FC<Props> = ({
  samlRequest,
  relayState,
  teamId,
  appKey,
  appName,
  appId,
  hasValidForcedPasswordLoginDisplayToken,
}) => {
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')
  const [externals, setExternals] = useState<ExternalIdp[]>([])
  const [showPassword, setShowPassword] = useState(false)
  const [usePasswordAuthenticationInWeb, setUsePasswordAuthenticationInWeb] =
    useState<boolean | undefined>(undefined)
  const [fingerprint, setFingerprint] = useState<FingerPrint>('')
  const [fingerprintHash, setFingerprintHash] = useState<FingerPrintHash>('')
  const [userAgent, setUserAgent] = useState('')
  const [{ ipAddress }] = useIpAddress()
  const [openSnackbar] = useSnackbar()

  const [postLoginLoading, setPostLoginLoading] = useState(false)

  const { t, i18n } = useTranslation()

  useEffect(() => {
    const subdomain = getSubdomain(window.location.host)
    getTeam(subdomain).then(
      ({
        data: {
          team: {
            team_authentication_setting: { use_password_authentication_in_web },
          },
        },
      }) => {
        setUsePasswordAuthenticationInWeb(use_password_authentication_in_web)
      },
    )
    getFingerprintAndHash().then(([fingerprint, fingerprintHash]) => {
      setFingerprint(fingerprint)
      setFingerprintHash(fingerprintHash)

      cookies.setLockedHashToCookie(fingerprintHash)
    })
    setUserAgent(navigator.userAgent)
    getExternalIdps(subdomain).then(({ external_idps }) => {
      setExternals(external_idps)
    })
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'email':
        setEmail(event.target.value)
        break
      case 'pass':
        setPass(event.target.value)
        break
      default:
        break
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key == 'Enter') {
      event.preventDefault()
      handleClick()
    }
  }

  const handleClick = async () => {
    if (!pass && !email) {
      openSnackbar('ログインに失敗しました', 'inputBlankSnackbar')
      return
    }
    setPostLoginLoading(true)

    const ua = await (
      navigator as Navigator
    ).userAgentData?.getHighEntropyValues(UADataKeys)

    const params = {
      email: email,
      password: pass,
      fingerprint: fingerprint,
      user_ip: ipAddress,
      hash: fingerprintHash,
      user_agent: userAgent,
      client_hint: {
        platform: ua?.platform ?? '',
        platform_version: ua?.platformVersion ?? '',
        brands: ua?.brands,
      },
      device_uuid: getDeviceUuid(),
      team_id: teamId,
      app_key: appKey,
      SAMLRequest: samlRequest,
      RelayState: relayState,
    }

    axios
      .post<SamlAuthPostResponse>(`${fullUrl()}/saml/auth/json`, params)
      .then((response) => {
        if (response.data.device_uuid) {
          cookies.setDeviceUuidToCookie(response.data.device_uuid)
        }

        if (response.data.action === 'allow') {
          if (
            typeof response.data.session_token_expired_at_minutes ===
            'undefined'
          ) {
            // error
            openSnackbar('ログインに失敗しました。', 'denySnackbar')
            return
          }
          const expires = cookies.generateSessionTokenExpires(
            response.data.session_token_expired_at_minutes,
          )
          cookies.setSessionTokenToCookie(
            response.data.session_token ?? '',
            expires,
          )
          const params = { lockedSessionToken: response.data.session_token }
          new SendLockedSessionTokenToExtension(params)
            .exec()
            .then((result) => {
              if (result.success) {
                // do nothing
              } else {
                // do nothing
              }
            })
          getApps({
            sessionToken: response.data.session_token ?? '',
          }).then(async (response) => {
            if (response.status !== 200) {
              return
            }
            const extensionApps = convertAppsToExtensionApps(response.data.apps)
            await new SendUrlMatchFormBasedAuthParams({
              apps: extensionApps,
            }).exec()
          })

          identitiesCount({
            app_id: appId,
            session_token: cookies.get('session_token'),
          }).then((response) => {
            if (response.data.count && response.data.count > 1) {
              const payload: ToAccountChooserState = {
                samlRequest: samlRequest,
                relayState: relayState,
              }
              navigate(`/accountChooser?app_id=${appId}`, {
                state: payload,
              })
              return
            } else {
              const payload: ToTransferState = {
                samlRequest: samlRequest,
                relayState: relayState,
              }
              navigate(`/transfer?app_id=${appId}`, {
                state: payload,
              })
              return
            }
          })
        } else if (response.data.action === 'verify') {
          cookies.setLockedEventIdToCookie(response.data.event_id ?? '')
          navigate('/verify?saml=true')
        } else if (response.data.action === 'verify_secret_question') {
          cookies.setLockedEventIdToCookie(response.data.event_id ?? '')
          navigate(
            `/verifySecretQuestion?token=${response.data.login_try_token}&saml=true`,
          )
        } else if (response.data.action === 'verify_totp') {
          cookies.setLockedEventIdToCookie(response.data.event_id ?? '')
          navigate(
            `/verifyTotp?token=${response.data.login_try_token}&saml=true`,
          )
        } else {
          const message =
            response.data.client_error_message || 'ログインに失敗しました。'
          openSnackbar(message, 'denySnackbar')
        }
      })
      .finally(() => {
        setPostLoginLoading(false)
      })

    return
  }

  const goExternalAuthorization = async (external: ExternalIdp) => {
    const origin = fullUrl()
    if (typeof origin !== 'string') return

    const url = new URL(
      `${origin}/saml/sso/${external.idp_type}/${external.id_hash}`,
    )
    url.searchParams.set(
      'RelayState',
      [appKey, samlRequest, relayState].join(LOCKED_RELAY_STATE_SEPARATOR),
    )
    location.href = url.href
  }

  const showPasswordAuthentication =
    hasValidForcedPasswordLoginDisplayToken || usePasswordAuthenticationInWeb

  return (
    <Layout>
      <div className="flex flex-col justify-center items-center">
        <div
          className="flex flex-col justify-center items-center"
          style={{
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '117%',
            color: '#070205',
          }}
        >
          <span className="mb-1">{t('サービスを利用するには、')}</span>
          <span>{t('LOCKEDにログインしてください。')}</span>
        </div>
        <div
          className="flex flex-col justify-center items-center mt-6 mb-5"
          style={{
            background: '#EDEDED',
            borderRadius: '2px',
            padding: '9px 11px',
          }}
        >
          <span
            className="mb-1"
            style={{ fontSize: '15px', lineHeight: '117%', color: '#070205' }}
          >
            {t('連携先サービス')}
          </span>
          <span
            style={{
              fontWeight: 'bold',
              fontSize: '16px',
              lineHeight: '117%',
              color: '#1133A2',
            }}
          >
            {appName}
          </span>
        </div>
        {showPasswordAuthentication && (
          <>
            <div className="w-full px-6">
              <p className="text-xs font-semibold text-gray-700 pb-1">
                {t('メールアドレス')}
              </p>
              <input
                aria-label="email"
                className="rounded px-2 w-full h-8 border border-black"
                data-test="email"
                type="text"
                name="email"
                value={email}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
            </div>
            <div className="w-full px-6 pt-4">
              <p className="text-xs font-semibold text-gray-700 pb-1">
                {t('パスワード')}
              </p>
              <div className="w-full relative">
                <input
                  aria-label="password"
                  className="rounded pl-2 pr-8  w-full h-8 border border-black"
                  data-test="pass"
                  type={showPassword ? 'text' : 'password'}
                  name="pass"
                  value={pass}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                />
                <div
                  onClick={() => setShowPassword(!showPassword)}
                  className="cursor-pointer absolute top-0 right-0 flex items-center mr-2"
                  style={{ height: 32 }}
                >
                  {showPassword ? (
                    <img className="h-6" src={OpenEyeSvg}></img>
                  ) : (
                    <img className="h-6" src={CloseEyeSvg}></img>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full mt-8 py-1 px-6">
              <button
                aria-label="submit"
                className="h-12 px-1 locked-btn flex items-center  rounded w-full"
                style={{ background: '#1133A2' }}
                data-test="submit"
                onClick={handleClick}
              >
                {postLoginLoading && (
                  <div
                    className="flex justify-center p-2"
                    style={{ width: '100%' }}
                  >
                    <Loading width={20} height={20}></Loading>
                  </div>
                )}
                {!postLoginLoading && (
                  <div className="flex items-center w-full justify-between">
                    <svg
                      width="38"
                      height="37"
                      viewBox="0 0 38 37"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="2.5"
                        y="1"
                        width="34"
                        height="34"
                        rx="2"
                        fill="white"
                      />
                      <path
                        d="M23.1696 16.3634L19.7887 19.7515L13.5649 13.5143L18.4825 8.58607C20.0961 6.969 22.7086 6.969 24.3222 8.58607L24.7064 8.97108C25.4747 9.74112 25.9358 10.8192 25.9358 11.8972C25.9358 12.9752 25.4747 14.0533 24.7064 14.8233L24.0916 15.4393L25.9358 17.2874L26.5505 16.6714C27.8567 15.3623 28.5482 13.6683 28.5482 11.8972C28.5482 10.1261 27.8567 8.35506 26.5505 7.12301L26.1663 6.73799C23.5538 4.11989 19.2509 4.11989 16.6384 6.73799L11.2597 12.1282C10.8755 12.5132 10.645 12.9752 10.645 13.5143C10.645 14.0533 10.8755 14.5153 11.2597 14.9003L18.4056 22.0616C18.7898 22.4466 19.3277 22.6776 19.7887 22.6776C20.3266 22.6776 20.7876 22.4466 21.1718 22.0616L25.0137 18.2115L23.1696 16.3634Z"
                        fill="#4870D3"
                      />
                      <path
                        d="M28.0092 20.8895L20.8479 13.7436C20.4629 13.3594 20.0009 13.1289 19.4619 13.1289C18.9229 13.1289 18.4608 13.3594 18.0758 13.7436L14.2257 17.5855L16.0737 19.4296L19.4619 16.0487L25.6991 22.2726L20.7709 27.1902C19.1539 28.8038 16.5358 28.8038 14.9187 27.1902L14.5337 26.806C12.9166 25.1925 12.9166 22.58 14.5337 20.9664L15.1497 20.3517L13.2246 18.5076L12.6086 19.1223C9.99049 21.7347 9.99049 26.0377 12.6086 28.6502L12.9936 29.0343C14.3027 30.3406 16.0737 31.0321 17.7678 31.0321C19.5389 31.0321 21.2329 30.3406 22.542 29.0343L27.9322 23.6557C28.3172 23.2715 28.5482 22.8105 28.5482 22.2726C28.5482 21.7347 28.3942 21.2737 28.0092 20.8895Z"
                        fill="#2222A0"
                      />
                    </svg>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: '16px',
                        lineHeight: '139%',
                        letterSpacing: '0.04em',
                        color: '#FFFFFF',
                      }}
                    >
                      {t('ログイン')}
                    </span>
                    <div
                      style={{ width: 34, height: 34 }}
                      className="flex justify-center items-center"
                    >
                      <svg
                        width="8"
                        height="13"
                        viewBox="0 0 8 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.49996 0.5L0.0849609 1.915L4.66996 6.5L0.0849609 11.085L1.49996 12.5L7.49996 6.5L1.49996 0.5Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                )}
              </button>
            </div>
          </>
        )}
        {externals.length > 0 && showPasswordAuthentication && (
          <hr
            style={{ width: '100%', marginBottom: '-15px', marginTop: '20px' }}
          />
        )}
        {showPasswordAuthentication === false && (
          <div style={{ height: '20px', width: '100%' }} />
        )}
        {externals.map((external) => (
          <div className="w-full px-6 mt-8">
            <button
              className="h-12 font-semibold w-full rounded bg-locked text-white hover:opacity-75"
              data-test="externalIdpSubmit"
              onClick={() => goExternalAuthorization(external)}
              disabled={postLoginLoading}
              style={{
                backgroundColor: external.color_hex,
                borderColor: external.color_hex,
              }}
            >
              {i18n.language === 'ja' && `${external.label}でログイン`}
              {i18n.language === 'en' && `Log in with ${external.label}`}
              {i18n.language === 'zh' && `用${external.label}登录`}
            </button>
          </div>
        ))}
        {showPasswordAuthentication === false && (
          <div style={{ height: '20px', width: '100%' }} />
        )}
        <div className="mt-5">
          <a
            className="locked-help-link"
            href="https://www.notion.so/afa58f9f8c7b4e88acfcc43505da543f"
            target="_blank"
          >
            {t('ログインに関するヘルプ')}
          </a>
        </div>
        {showPasswordAuthentication && (
          <div className="mt-2">
            <a className="locked-help-link" href="/passwordResetRequest">
              {t('パスワードを忘れた方はこちら')}
            </a>
          </div>
        )}
        <div className="mb-8" />
      </div>
    </Layout>
  )
}

export default Index
