import { GetForcedPasswordLoginDisplayTokenIsValidByAppKeyResponse } from 'types/ApiResponse'
import { axiosClient } from 'scripts/api'
import { fullUrl } from 'scripts/fullUrl'

export const getForcedPasswordLoginDisplayTokenIsValidByAppKey =
  async (params: {
    app_key: string
    forced_password_login_display_token: string
  }) => {
    const url = `${fullUrl(
      'api',
    )}/teams/forced_password_login_display_token_is_valid_by_app_key`
    return axiosClient.get<GetForcedPasswordLoginDisplayTokenIsValidByAppKeyResponse>(
      url,
      { params },
    )
  }
