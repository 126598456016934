import { GetAppResponse } from 'types/ApiResponse'
import { useAxios } from 'scripts/api'
import { GetAppByKeyRequest } from 'types/ApiRequest'
import { fullUrl } from 'scripts/fullUrl'

export const useAppByKey = (params: GetAppByKeyRequest) => {
  const [{ data, error, loading }] = useAxios<GetAppResponse>({
    url: `${fullUrl()}/apps/show_by_key`,
    params: params,
  })
  return [{ ...data, error, loading }]
}
