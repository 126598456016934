import { GetExternalIdpsResponse } from 'types/ApiResponse'
import { axiosClient } from 'scripts/api'
import { fullUrl } from 'scripts/fullUrl'

export const getExternalIdps = async (subdomain: string) => {
  const { data } = await axiosClient.get<GetExternalIdpsResponse>(
    `${fullUrl()}/external_idps`,
    { params: { subdomain: subdomain } },
  )
  return data
}
