import React, { useEffect, useState } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { cookies } from 'scripts/cookies'
import { Loading } from 'presentation/components/Loading'
import { navigate, PageProps } from 'gatsby'
import axios from 'axios'
import { fullUrl } from 'scripts/fullUrl'
import { SamlAuthNewResponse, LoginPingResponse } from 'types/ApiResponse'
import SamlNew from 'presentation/components/Saml/New'
import { identitiesCount } from 'api/identitiesCount'
import { useAppByKey } from 'api/useAppId'
import { ToAccountChooserState, ToTransferState } from 'types/NavigateState'
import { getSubdomain } from 'scripts/getSubdomainForHost'
import { getForcedPasswordLoginDisplayTokenIsValidByAppKey } from 'api/getForcedPasswordLoginDisplayTokenIsValidByAppKey'
import { sentryCaptureMessage } from 'scripts/sentryCaptureMessage'

export const AppKey = (props: PageProps) => {
  const appKey: string = props.params['*']
  const targetAppKey = '0423c93f6b0ccf310c744abd52e41dcd'
  const isTargetApp = appKey === targetAppKey
  const sentryParams: Record<string, any> = {
    appKey,
  }
  const sentryWithTargetApp = (message: string, data?: Record<string, any>) => {
    if (!isTargetApp) return

    sentryCaptureMessage(message, data)
  }
  sentryWithTargetApp('start1', sentryParams)
  const [samlRequest] = useQueryParam('SAMLRequest', StringParam)
  sentryParams['samlRequest'] = samlRequest
  sentryWithTargetApp('start2', sentryParams)
  const [relayState] = useQueryParam('RelayState', StringParam)
  sentryParams['relayState'] = relayState
  sentryWithTargetApp('start3', sentryParams)

  const sessionToken = cookies.get('session_token')
  sentryParams['sessionToken'] = sessionToken
  sentryWithTargetApp('start4', sentryParams)

  const [{ app }] = useAppByKey({
    app_key: appKey,
  })
  sentryParams['app'] = app
  sentryWithTargetApp('start5', sentryParams)

  const [teamId, setTeamId] = useState<number>()
  const [appName, setAppName] = useState('')

  const [loading, setLoading] = useState(true)
  const [showLoginForm, setShowLoginForm] = useState(false)

  const [forcedPasswordLoginDisplayTokenQueryParam] = useQueryParam(
    'forced_password_login_display_token',
    StringParam,
  )
  sentryParams['forcedPasswordLoginDisplayTokenQueryParam'] =
    forcedPasswordLoginDisplayTokenQueryParam
  sentryWithTargetApp('start6', sentryParams)
  const [
    hasValidForcedPasswordLoginDisplayToken,
    setHasValidForcedPasswordLoginDisplayToken,
  ] = useState<boolean>(false)

  useEffect(() => {
    if (app) {
      sentryWithTargetApp('start7', sentryParams)
      axios
        .get<LoginPingResponse>(`${fullUrl()}/login/ping`, {
          params: { session_token: sessionToken },
        })
        .then((response) => {
          sentryParams['login_ping_response'] = response
          sentryWithTargetApp('start8', sentryParams)
          if (response.data.valid) {
            sentryWithTargetApp('start9', sentryParams)
            identitiesCount({
              app_id: app.id,
              session_token: cookies.get('session_token'),
            }).then((response) => {
              sentryParams['identitiesCountResponse'] = response
              sentryWithTargetApp('start10', sentryParams)
              if (response.data.count && response.data.count > 1) {
                const payload: ToAccountChooserState = {
                  samlRequest: samlRequest,
                  relayState: relayState,
                }
                navigate(`/accountChooser?app_id=${app.id}`, {
                  state: payload,
                })
                return
              } else {
                const payload: ToTransferState = {
                  samlRequest: samlRequest,
                  relayState: relayState,
                }
                navigate(`/transfer?app_id=${app.id}`, {
                  state: payload,
                })
                return
              }
            })
          } else {
            sentryWithTargetApp('start11', sentryParams)
            axios
              .get<SamlAuthNewResponse>(`${fullUrl()}/saml/auth/json`, {
                params: {
                  app_key: appKey,
                },
              })
              .then((response) => {
                sentryParams['samlAuthNewResponse'] = response
                sentryWithTargetApp('start12', sentryParams)
                setTeamId(response.data.team_id)
                setAppName(response.data.app_name ?? '')
                setShowLoginForm(true)
              })
              .catch((e) => {
                sentryParams['samlAuthNewResponseError'] = e
                sentryWithTargetApp('start13', sentryParams)
                // error
              })
              .finally(() => {
                sentryWithTargetApp('start14', sentryParams)
                setLoading(false)
              })
          }
        })
        .finally(() => {
          sentryWithTargetApp('start15', sentryParams)
          setLoading(false)
        })
    }
  }, [app])

  useEffect(() => {
    ;(async () => {
      if (app === undefined) {
        return
      }
      if (
        typeof forcedPasswordLoginDisplayTokenQueryParam !== 'string' ||
        forcedPasswordLoginDisplayTokenQueryParam === ''
      ) {
        return
      }
      if (typeof appKey !== 'string' || appKey === '') {
        return
      }

      const { data, status } =
        await getForcedPasswordLoginDisplayTokenIsValidByAppKey({
          forced_password_login_display_token:
            forcedPasswordLoginDisplayTokenQueryParam,
          app_key: appKey,
        })
      if (status !== 200) {
        return
      }

      setHasValidForcedPasswordLoginDisplayToken(
        data.is_valid_forced_password_login_display_token,
      )
    })()
  }, [app, forcedPasswordLoginDisplayTokenQueryParam, appKey])

  if (!loading && showLoginForm) {
    return (
      <SamlNew
        samlRequest={samlRequest!}
        relayState={relayState!}
        teamId={teamId!}
        appName={appName!}
        appKey={appKey!}
        appId={app!.id}
        hasValidForcedPasswordLoginDisplayToken={
          hasValidForcedPasswordLoginDisplayToken
        }
      ></SamlNew>
    )
  }

  return (
    <main className="w-full h-full flex justify-center items-center">
      <Loading></Loading>
    </main>
  )
}
